import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";

import { db } from "../firebase";

import {
    query,
    collection,
    getDocs,
    where,
    limit,
    doc,
    onSnapshot,
    setDoc,
} from "firebase/firestore";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";

const IngredientsAddImage = (props) => {
    const [ingredients, setIngredients] = useState([]);
    const [file, setFile] = useState("");

    const [percent, setPercent] = useState(0);

    const storage = getStorage();

    useEffect(() => {
        getIngredients();
    }, []);

    const getIngredients = async () => {
        const q = query(collection(db, "ingredients"), where("userId", "==", null));
        const querySnapshot = onSnapshot(q, (querySnapshot) => {
            const ing = [];
            querySnapshot.forEach((doc) => {
                const ingredient = doc.data()
                ingredient.docId = doc.id
                ing.push(ingredient)
            });
            setIngredients(ing)
        })
    };

    const getImage = (imageUrl) => {
        if (imageUrl) {
            return imageUrl;
        } else {
            return "https://firebasestorage.googleapis.com/v0/b/askvikdevelopmentwebsite.appspot.com/o/foodwinlogo512.png?alt=media&token=58a4b6d6-41f8-4dda-a8f5-36f454e3097d";
        }
    };

    const handleUpload = (docId) => {
        if (!file) {
            alert("Velg en fil først");
        }

        const storageRef = ref(storage, `/ingredients/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                ); // update progress
                setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    console.log(url);
                    document.getElementById("image").src = url;

                    const ingredientRef = doc(db, "ingredients", docId);
                    setDoc(ingredientRef, { imageUrl: url }, { merge: true });
                });
            }
        );
    };

    function handleFileChange(event) {
        setFile(event.target.files[0]);
    }
    return (
        <>
            {ingredients.length > 0 && (
                <>
                    {ingredients.map((doc, index) => {
                        return (
                            <div className="foodItemContainerImage" key={doc.docId}>
                                <p className="textInList boldText">
                                    {doc.name.NB}
                                </p>
                                <p className="textInList">
                                    {doc.amount} {doc.unitOfMeasurement}
                                </p>

                                <img
                                    className="logoInList"
                                    id={"image"}
                                    src={getImage(doc.imageUrl)}
                                />
                                <input
                                    type="file"
                                    className="uploadImage, p-3"
                                    id={"item" + index}
                                    onChange={handleFileChange}
                                ></input>
                                <Button
                                    className="w-100 mt-4"
                                    variant="success"
                                    type="submit"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        handleUpload(doc.docId);
                                    }}
                                >
                                    Last opp bilde til Firestore
                                </Button>
                                <p className="mt-4">{percent}% fullført</p>
                            </div>
                        );
                    })}
                </>
            )}
        </>
    );
};

export default IngredientsAddImage;
