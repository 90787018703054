import { db } from "../firebase";

import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
} from "firebase/firestore";

const foodCollectionRef = collection(db, "foods");
const ingredientsCollectionRef = collection(db, "ingredients");
const newFoodCollectionRef = collection(db, "newFoods");
class FoodDataService {
    addFoods = (newFood) => {
        return addDoc(foodCollectionRef, newFood);
    };

    addIngredient = (ingredient) => {
        return addDoc(ingredientsCollectionRef, ingredient);
    };

    updateFood = (id, updatedFood) => {
        const foodDoc = doc(db, "foods", id);
        return updateDoc(foodDoc, updatedFood);
    };

    deleteFood = (id) => {
        const foodDoc = doc(db, "foods", id);
        return deleteDoc(foodDoc);
    };

    getFoods = () => {
        return getDocs(foodCollectionRef);
    };

    getNewFoods = () => {
        return getDocs(newFoodCollectionRef);
    };
}

export default new FoodDataService();
