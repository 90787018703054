import React from "react";
import { useState } from "react";
import FoodDataService from "../services/foods.services";
import { Form, Button } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import { db } from "../firebase";

import { query, collection, getDocs, where } from "firebase/firestore";

const UnitOfMeasurement = {
    STK: "STK",
    MG: "MG",
    G: "G",
    KG: "KG",
    ML: "ML",
    DL: "DL",
    L: "L",
}

const FoodCategory = {
    VEGETABLES: "VEGETABLES",
    NUTS: "NUTS",
    MEAT: "MEAT",
    POULTRY: "POULTRY",
    SEAFOOD: "SEAFOOD",
    PASTA: "PASTA",
    RICE: "RICE",
    GRAINS: "GRAINS",
    BREAD: "BREAD",
    BAKING_GOODS: "BAKING_GOODS",
    DAIRY: "DAIRY",
    BUTTER: "BUTTER",
    CHEESE: "CHEESE",
    EGG: "EGG",
    SWEETS: "SWEETS",
    DRINKS: "DRINKS",
    SPICES_AND_HERBS: "SPICES_AND_HERBS",
    LENTILS_AND_SEEDS: "LENTILS_AND_SEEDS",
    SAUCES_AND_CONDIMENTS: "SAUCES_AND_CONDIMENTS",
    OIL_AND_VINEGAR: "OIL_AND_VINEGAR",
    SNACKS_AND_CANDY: "SNACKS_AND_CANDY",
    OTHER: "OTHER"
}

const AddIngredient = () => {
    const [nbName, setNbName] = useState("");
    const [enName, setEnName] = useState("");
    const [amount, setAmount] = useState(0);
    const [unitOfMeasurement, setUnitOfMeasurement] = useState(UnitOfMeasurement.STK);
    const [category, setCategory] = useState(FoodCategory.OTHER);

    const ingredientExists = async () => {
        const q = query(collection(db, "ingredients"), where("name.NB", "==", nbName), where("userId", "==", null));
        const querySnapshot = await getDocs(q);
        console.log(querySnapshot.docs.map((snapshot) => snapshot.id))
        return !querySnapshot.empty;
    };

    const resetName = () => {
        setEnName("");
        setNbName("");
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const name = {
            NB: nbName,
            EN: enName
        }

        const ingredient = {
            name,
            amount,
            unitOfMeasurement,
            category,
            "userId": null
        };

        if (await ingredientExists() === true) {
            console.log("Matvaren er allerede lagt til i databasen");
            return;
        }

        const shouldAdd = window.confirm(`Er du sikker på at du vil legge til: ${name.NB}?`);

        try {
            if (shouldAdd) {
                await FoodDataService.addIngredient(ingredient);
                console.log(`${nbName} ble lagt til i databasen!`);
                resetName();
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <div className="p-4 box text-center overflow-auto position-relative">
                <Link to="/home">
                    <Button
                        className="position-absolute start-0 top-0 m-3"
                        variant="light"
                    >
                        &lt;
                    </Button>
                </Link>
                <img
                    src="https://firebasestorage.googleapis.com/v0/b/askvikdevelopmentwebsite.appspot.com/o/foodwinlogo512.png?alt=media&token=58a4b6d6-41f8-4dda-a8f5-36f454e3097d"
                    alt="Logo"
                    id="logo"
                />
                <h2 className="mb-3">Legg til ingrediens</h2>
                <hr />

                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="foodItem">
                        <Form.Label>Matvare Norsk:</Form.Label>
                        <Form.Control
                            value={nbName}
                            type="text"
                            onChange={(e) =>
                                setNbName(e.target.value)
                            }
                        />
                    </Form.Group>


                    <Form.Group className="mb-3" controlId="foodItem">
                        <Form.Label>Matvare Engelsk:</Form.Label>
                        <Form.Control
                            value={enName}
                            type="text"
                            onChange={(e) =>
                                setEnName(e.target.value)
                            }
                        />
                    </Form.Group>

                    <div className="container">
                        <div className="row">
                            <Form.Group
                                className="mb-3 col-6 pe-3"
                                controlId="amount"
                            >
                                <Form.Label>Mengde:</Form.Label>
                                <Form.Control
                                    type="number"
                                    onChange={(e) => {
                                        if (e.target.value.includes(",")) {
                                            setAmount(
                                                parseFloat(
                                                    e.target.value.replace(
                                                        ",",
                                                        "."
                                                    )
                                                )
                                            );
                                        } else {
                                            setAmount(
                                                parseFloat(e.target.value)
                                            );
                                        }
                                    }}
                                />
                            </Form.Group>

                            <Form.Group
                                className="mb-3 col-6 ps-3"
                                controlId="unitOfMeasurement"
                            >
                                <Form.Label>Enhet:</Form.Label>
                                <Form.Select
                                    value={unitOfMeasurement}
                                    onChange={(e) =>
                                        setUnitOfMeasurement(e.target.value)
                                    }
                                >
                                    {Object.entries(UnitOfMeasurement).map((object) => {
                                        return <option value={object[1]}>{object[1]}</option>;
                                    })}
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </div>

                    <Form.Group className="mb-3" controlId="category">
                        <Form.Label>Kategori:</Form.Label>
                        <Form.Select
                            onChange={(e) => setCategory(e.target.value)}
                            defaultValue="1"
                        >
                            {Object.entries(FoodCategory).map((object) => {
                                return <option value={object[1]}>{object[1]}</option>;
                            })}
                        </Form.Select>
                    </Form.Group>


                    <Button className="w-100" variant="primary" type="submit">
                        Legg til matvare
                    </Button>
                </Form>
            </div>
        </>
    );
};

export default AddIngredient;
